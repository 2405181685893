import { createTheme } from '@material-ui/core';

const themeLocal = createTheme({
	palette: {
		primary: {
			main: '#333333',
		},
		secondary: {
			main: '#666666',
		},
		background: {
			default: '#333333',
		},
	},
});

export default themeLocal;
