import { createTheme } from '@material-ui/core';

const themeDSTProd = createTheme({
	palette: {
		primary: {
			main: '#DB3A6B',
		},
		secondary: {
			main: '#E85D87',
		},
		background: {
			default: '#DB3A6B',
		},
	},
});

export default themeDSTProd;
