.activeList {
	margin-top: 50px;
	width: 100%;
}
.activeList .scroller {
	height: 180px;
}

.selectionList {
	margin-top: 10px;
	width: 100%;
}

.selectionList .scroller {
	height: 250px;
}

.excludedDomainsContainerLabel {
	margin-left: 30px;
}
