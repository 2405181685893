
import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	FormControl,
	Icon,
	Input,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	withStyles,
} from '@material-ui/core';
import * as css from './TableHeaderComponent.css';

const styles = theme => ({
	root: {
		marginTop: theme.spacing(3),
	},
	iconAddBox: {
		color: theme.palette.primary.main,
		fontSize: 40,
		float: 'right',
		marginLeft: '30px',
	},
	tableCellNormal: {
		width: '90%',
	},
	tableCellIcon: {
		width: '10%',
	},
	addButtonIcon: {
		fontSize: '40px',
	},
	button: {
		padding: '8px',
		minWidth: '40px',
	},
	selectItem: {
		color: '#000',
	},
});

class SimpleTableHeader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			domain: '',
		};
	}

	render() {
		const { classes, title, add } = this.props;
		const { domain } = this.state;

		const handleChange = event => {
			this.setState({
				domain: event.target.value,
			});
		};

		const handleOnClick = () => {
			add(domain);
			this.setState({
				domain: '',
			});
		};

		return (
			<div className={css.tableHeaderComponentContainer}>
				<Paper classes={{ root: classes.root }}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell classes={{ root: classes.tableCellNormal }}>
									<Typography variant="subtitle1">{title}</Typography>
								</TableCell>
								<TableCell classes={{ root: classes.tableCellIcon }} />
							</TableRow>
						</TableBody>
						<TableBody>
							<TableRow>
								<TableCell classes={{ root: classes.tableCellNormal }}>
									<FormControl fullWidth>
										<Input
											value={domain}
											onChange={event => handleChange(event)}
										/>
									</FormControl>
								</TableCell>
								<TableCell classes={{ root: classes.tableCellIcon }}>
									<Button
										classes={{ root: classes.button }}
										color="primary"
										aria-label="add redirect"
										onClick={() => handleOnClick()}
									>
										<Icon classes={{ root: classes.addButtonIcon }}>
											add_box
										</Icon>
									</Button>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Paper>
			</div>
		);
	}
}

SimpleTableHeader.propTypes = {
	classes: PropTypes.object,
	title: PropTypes.string,
	add: PropTypes.func,
};

const SimpleTableHeaderWS = withStyles(styles)(SimpleTableHeader);

export { SimpleTableHeaderWS as SimpleTableHeader };
