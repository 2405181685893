import { createTheme } from '@material-ui/core';

const themeProd = createTheme({
	palette: {
		primary: {
			main: '#EC7063',
		},
		secondary: {
			main: '#F1948A',
		},
		background: {
			default: '#EC7063',
		},
	},
});

export default themeProd;
