
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Button,
	Tooltip,
	withStyles,
} from '@material-ui/core';
import { AppList } from './AppsList';
import { getDataHasChanges } from '../../selectors/getDataHasChanges';
import { PreviewModal } from '../PreviewModal/PreviewModal';
import { getConfigKeyData } from '../../selectors/getConfigKeyData';
import { getSelectedKey } from '../../selectors/getSelectedKey';
import {
	deleteCache,
	updateConfigData,
} from '../../actions/configActions';
import { getIsPossibleToPublish } from '../../selectors/getIsPossibleToPublish';
import { getIsPossibleToDeleteCache } from '../../selectors/getIsPossibleToDeleteCache';
import * as css from './Sidebar.css';

const styles = () => ({
	publishButton: {
		color: '#000',
		margin: 'auto',
	},
});

class Sidebar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};
	}

	render() {
		const {
			classes,
			state,
			selectedTenant,
			selectedEnv,
			updateData,
		} = this.props;
		const { open } = this.state;

		const disabled =
			!getDataHasChanges(state, null, null, 'isConfigKeyData') ||
			!getIsPossibleToPublish(state);

		const handleClickOpen = () => {
			this.setState({ open: true });
		};

		const handleClose = () => {
			this.setState({ open: false });
		};

		const handleDeleteCacheClicked = () => {
			const { invalidateCache } = this.props;
			invalidateCache();
		};

		return (
			<div className={css.sidebarContainer}>
				<AppList />
				<div className={css.buttonHr} />
				<div className={css.previewButton}>
					<Tooltip
						title={
							disabled ? 'You can only publish if you have valid changes!' : ''
						}
					>
						<span>
							<Button
								classes={{ root: classes.publishButton }}
								variant="contained"
								size="large"
								disabled={disabled}
								onClick={handleClickOpen}
							>
								Preview & Publish
							</Button>
						</span>
					</Tooltip>

					<PreviewModal
						open={open}
						onClose={handleClose}
						originalData={JSON.stringify(
							getConfigKeyData(state, 'original'),
							null,
							'\t',
						)}
						draftData={JSON.stringify(getConfigKeyData(state), null, '\t')}
						tenant={selectedTenant}
						env={selectedEnv}
						update={updateData}
					/>
				</div>
				<div className={css.deleteCacheButton}>
					<Button
						classes={{ root: classes.deleteCacheButton }}
						variant="contained"
						size="large"
						disabled={!getIsPossibleToDeleteCache(state)}
						onClick={handleDeleteCacheClicked}
					>
						Delete Cache
					</Button>
				</div>
			</div>
		);
	}
}

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	state: PropTypes.object,
	selectedEnv: PropTypes.string,
	selectedTenant: PropTypes.string,
	updateData: PropTypes.func,
	invalidateCache: PropTypes.func,
};

function mapStateToProps(state) {
	const selectedKey = getSelectedKey(state);
	const selectedTenant = selectedKey ? selectedKey.split('-')[0] : undefined;
	const selectedEnv = selectedKey ? selectedKey.split('-')[1] : undefined;
	return {
		state,
		selectedTenant,
		selectedEnv,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateData: newConfigData => dispatch(updateConfigData(newConfigData)),
		invalidateCache: () => dispatch(deleteCache()),
	};
}

const EnhancedSidebar = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(Sidebar));

export { EnhancedSidebar as Sidebar };
