import { isEmpty, path } from 'ramda';
import { getSelectedKey } from './getSelectedKey';

export function getAppsInfo(state) {
	const key = getSelectedKey(state);

	if (key && isEmpty(state.configDraft.byKey[key])) {
		return [];
	}

	if (key && state.configDraft.byKey[key]) {
		const apps = path(
			[
				'configDraft',
				'byKey',
				key,
				'query',
				'qry.tenant.get-full',
				'data',
				'private',
				'apps',
			],
			state,
		);

		return Object.keys(apps).map(appName => ({
			displayName: apps[appName].name,
			appName,
			appKey: apps[appName].key,
			appDescription: apps[appName].descr,
		}));
	}

	return undefined;
}
