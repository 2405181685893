import {
	ExpandLess,
	ExpandMore,
} from '@material-ui/icons';
import {
	Button,
	Collapse,
	FormControl,
	Grid,
	Icon,
	Input,
	List,
	ListItem,
	ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	withStyles,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { concat, sort, uniq } from 'ramda';
import {
	addDomain,
	discardAppData,
	removeDomain,
	updateListItem,
} from '../../../actions/configActions';
import {
	PATH_AMAG_DEALER_DOMAIN_MAPPING,
	PATH_CUSTOM_CONFIG,
	PATH_DISCARD_AMAG_DEALERS,
	PATH_DOMAINS,
	PATH_REDIRECT_TLS,
	PATH_REDIRECT_TLS_EXCLUDED_DOMAINS,
	PATH_REDIRECT_WWW,
	PATH_REDIRECT_WWW_EXCLUDED_DOMAINS,
} from '../../../constants';
import { getAppData } from '../../../selectors/getAppData';
import { DiscardButton } from '../DiscardButton/DiscardButton';
import { RedirectsList } from '../RedirectsList/RedirectsList';
import { SimpleTableHeader } from '../TableHeader/SimpleTableHeader';
import * as css from './PageDealers.css';

const styles = () => ({
	listItemRoot: {},
	listItemTextPrimary: {
		color: 'black',
	},
	collapseWrapperInner: {
		background: '#ddd',
	},
});

// amag-[dealer].spectra.io
const websiteDealerRegex = /^amag-([^.]+)\./;

class PageDealers extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render(props) {
		const {
			discard,
			classes,
			isBrandPage,
		} = props;
		const { newDealerLabel, newCustomDomain, newSpectraDomain } = this.state;

		const handleSetExpanded = (dealerLabel, isCollapsed) => this.setState({ [dealerLabel]: isCollapsed });

		const handleChange = (event, prop) => {
			const newValue = event.target.value;
			this.setState({ [prop]: newValue });
		};

		const getIsExpanded = (dealerLabel) => {
			const { [dealerLabel]: isExpanded } = this.state;
			return isExpanded;
		};

		const getDealerLabels = () => {
			const { domainsData, dealerDomainMappingData } = this.props;

			const dealerSpectraDomains = domainsData.filter(domain => domain && domain.startsWith('amag-') && domain.endsWith('.spectra.io'));
			const spectraDomainsDealerLabels = dealerSpectraDomains.map(domain => websiteDealerRegex.test(domain) ? websiteDealerRegex.exec(domain)[1] : null);
			const dealerDoaminMappingDealerLabels = Object.keys(dealerDomainMappingData);

			const dealerLabels = isBrandPage ? dealerDoaminMappingDealerLabels : uniq(concat(spectraDomainsDealerLabels, dealerDoaminMappingDealerLabels));
			return sort((a, b) => a.localeCompare(b), dealerLabels)
		};

		const dealerLabels = getDealerLabels();

		const getSpectraDomainsForDealer = (dealerLabel) => {
			const { domainsData } = this.props;
			return domainsData.filter(domain => domain.startsWith(`amag-${dealerLabel}.`));
		};

		const getCustomDomainsForDealer = (dealerLabel) => {
			const { dealerDomainMappingData } = this.props;
			return dealerDomainMappingData[dealerLabel] || [];
		};

		const addToDomains = (domain) => {
			const { addDomainToConfig } = this.props;
			if (domain) {
				addDomainToConfig(domain, PATH_DOMAINS);
			}
		};

		const addToWWWRedirectExclude = (domain) => {
			const { addDomainToConfig, wwwRedirectData } = this.props;
			if (Boolean(wwwRedirectData) && domain && domain.includes('.spectra.io')) {
				addDomainToConfig(domain, PATH_REDIRECT_WWW_EXCLUDED_DOMAINS);
			}
		};

		const addToTLSRedirectExclude = (domain) => {
			const { addDomainToConfig } = this.props;
			if (domain && domain.includes('.spectra.local')) {
				addDomainToConfig(domain, PATH_REDIRECT_TLS_EXCLUDED_DOMAINS);
			}
		};

		const removeFromDomains = (domain) => {
			const { removeDomainFromConfig, domainsData } = this.props;

			const indexInDomains = domainsData.indexOf(domain);
			removeDomainFromConfig(indexInDomains, PATH_DOMAINS);
		};

		const removeFromWWWRedirectExclude = (domain) => {
			const { removeDomainFromConfig, wwwRedirectData } = this.props;

			if (Boolean(wwwRedirectData) && domain && domain.includes('.spectra.io')) {
				const indexInWwwExcludedDomains = wwwRedirectData.excludedDomains.indexOf(domain);
				if (indexInWwwExcludedDomains !== -1) {
					removeDomainFromConfig(indexInWwwExcludedDomains, PATH_REDIRECT_WWW_EXCLUDED_DOMAINS);
				}
			}
		};

		const removeFromTLSRedirectExclude = (domain) => {
			const { removeDomainFromConfig, tlsRedirectData } = this.props;

			if (domain && domain.includes('.spectra.local')) {
				const indexInTlsExcludedDomains = tlsRedirectData.excludedDomains.indexOf(domain);
				if (indexInTlsExcludedDomains !== -1) {
					removeDomainFromConfig(indexInTlsExcludedDomains, PATH_REDIRECT_TLS_EXCLUDED_DOMAINS);
				}
			}
		};

		const updateInDomains = (oldDomain, newDomain) => {
			const { updateDomainInConfig, domainsData } = this.props;

			const indexInDomains = domainsData.indexOf(oldDomain);
			updateDomainInConfig(indexInDomains, newDomain, PATH_DOMAINS);
		};

		const updateInWWWRedirectExclude = (oldDomain, newDomain) => {
			const { updateDomainInConfig, wwwRedirectData } = this.props;

			if (Boolean(wwwRedirectData) && oldDomain.includes('.spectra.io')) {
				const indexInWwwExcludedDomains = wwwRedirectData.excludedDomains.indexOf(oldDomain);
				if (indexInWwwExcludedDomains !== -1) {
					updateDomainInConfig(indexInWwwExcludedDomains, newDomain, PATH_REDIRECT_WWW_EXCLUDED_DOMAINS);
				}
			}
		};

		const updateInTLSRedirectExclude = (oldDomain, newDomain) => {
			const { updateDomainInConfig, tlsRedirectData } = this.props;

			if (oldDomain.includes('.spectra.local')) {
				const indexInTlsExcludedDomains = tlsRedirectData.excludedDomains.indexOf(oldDomain);
				if (indexInTlsExcludedDomains !== -1) {
					updateDomainInConfig(indexInTlsExcludedDomains, newDomain, PATH_REDIRECT_TLS_EXCLUDED_DOMAINS);
				}
			}
		};

		const addCustomDomain = (dealerLabel, domain) => {
			const { addDomainToConfig } = this.props;
			const dealerDomainMappingPath = [[...(PATH_AMAG_DEALER_DOMAIN_MAPPING[0]), dealerLabel]];

			// add to customConfig.dealerDomainMapping
			addDomainToConfig(domain, dealerDomainMappingPath);

			addToDomains(domain);
			addToWWWRedirectExclude(domain);
			addToTLSRedirectExclude(domain);
		};

		const removeCustomDomain = (dealerLabel, index) => {
			const { removeDomainFromConfig } = this.props;
			const dealerDomainMappingPath = [[...(PATH_AMAG_DEALER_DOMAIN_MAPPING[0]), dealerLabel]];
			const customDomains = getCustomDomainsForDealer(dealerLabel);

			// remove from customConfig.dealerDomainMapping
			removeDomainFromConfig(index, dealerDomainMappingPath);

			const domain = customDomains[index];

			removeFromDomains(domain);
			removeFromWWWRedirectExclude(domain);
			removeFromTLSRedirectExclude(domain);
		};

		const updateCustomDomain = (dealerLabel, index, updatedDomain) => {
			const { updateDomainInConfig } = this.props;
			const dealerDomainMappingPath = [[...(PATH_AMAG_DEALER_DOMAIN_MAPPING[0]), dealerLabel]];
			const customDomains = getCustomDomainsForDealer(dealerLabel);

			// update in customConfig.dealerDomainMapping
			updateDomainInConfig(index, updatedDomain, dealerDomainMappingPath);

			const domain = customDomains[index];

			updateInDomains(domain, updatedDomain);
			updateInWWWRedirectExclude(domain, updatedDomain);
			updateInTLSRedirectExclude(domain, updatedDomain);
		};

		const addSpectraDomain = (domain) => {
			addToDomains(domain);
			addToWWWRedirectExclude(domain);
			addToTLSRedirectExclude(domain);
		};

		const removeSpectraDomain = (dealerLabel, index) => {
			const spectraDomains = getSpectraDomainsForDealer(dealerLabel)
			const domain = spectraDomains[index];

			removeFromDomains(domain);
			removeFromWWWRedirectExclude(domain);
			removeFromTLSRedirectExclude(domain);
		};

		const updateSpectraDomain = (dealerLabel, index, updatedDomain) => {
			const spectraDomains = getSpectraDomainsForDealer(dealerLabel)
			const domain = spectraDomains[index];

			updateInDomains(domain, updatedDomain);
			updateInWWWRedirectExclude(domain, updatedDomain);
			updateInTLSRedirectExclude(domain, updatedDomain);
		};

		const handleOnAddClick = () => {
			if (newDealerLabel && (newCustomDomain || newSpectraDomain)) {

				if (newCustomDomain) {
					addCustomDomain(newDealerLabel, newCustomDomain);
				}

				if (newSpectraDomain && !isBrandPage) {
					addSpectraDomain(newSpectraDomain);
				}
			}

			this.setState({ newDealerLabel: '', newCustomDomain: '', newSpectraDomain: ''});
		};

		return (
			<>
				<Grid container>
					<Grid item sm={10}>
						<div className={css.contentHeader}>
							<Typography variant="h3">Dealers</Typography>
						</div>
					</Grid>
					<Grid container item sm={2} alignItems="center">
						<Grid item>
							<DiscardButton text="Discard Changes" onClick={discard} />
						</Grid>
					</Grid>
				</Grid>
				<div className={css.container}>
					<Paper classes={{ root: classes.root }}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell classes={{ root: classes.tableCellNormal }}>
										<Typography variant="subtitle1">Dealer Label</Typography>
									</TableCell>
									<TableCell classes={{ root: classes.tableCellNormal }}>
										<Typography variant="subtitle1">Custom Domain</Typography>
									</TableCell>
									{!isBrandPage && <TableCell classes={{ root: classes.tableCellNormal }}>
										<Typography variant="subtitle1">Spectra Domain</Typography>
									</TableCell>}
									<TableCell classes={{ root: classes.tableCellIcon }} />
								</TableRow>
							</TableBody>
							<TableBody>
								<TableRow>
									<TableCell classes={{ root: classes.tableCellNormal }}>
										<FormControl>
											<Input
												value={newDealerLabel}
												onChange={event => handleChange(event, 'newDealerLabel')}
											/>
										</FormControl>
									</TableCell>
									<TableCell classes={{ root: classes.tableCellNormal }}>
										<FormControl>
											<Input
												value={newCustomDomain}
												onChange={event => handleChange(event, 'newCustomDomain')}
											/>
										</FormControl>
									</TableCell>
									{!isBrandPage && <TableCell classes={{ root: classes.tableCellNormal }}>
										<FormControl>
											<Input
												value={newSpectraDomain}
												onChange={event => handleChange(event, 'newSpectraDomain')}
											/>
										</FormControl>
									</TableCell>}
									<TableCell classes={{ root: classes.tableCellIcon }}>
										<Button
											classes={{ root: classes.button }}
											color="primary"
											aria-label="add dealer"
											onClick={handleOnAddClick}
										>
											<Icon classes={{ root: classes.addButtonIcon }}>
												add_box
											</Icon>
										</Button>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
					<List>
						{dealerLabels.map(dealerLabel => {
							const isExpanded = getIsExpanded(dealerLabel);

							return (
								<>
									<ListItem
										classes={{ root: classes.listItemRoot }}
										button
										onClick={() => handleSetExpanded(dealerLabel, !isExpanded)}
										divider
									>
										<ListItemText primary={dealerLabel} classes={{ primary: classes.listItemTextPrimary }} />
										{isExpanded ? <ExpandLess />  : <ExpandMore />}
									</ListItem>
									<Collapse
										classes={{ wrapperInner: classes.collapseWrapperInner }}
										in={isExpanded}
										timeout="auto"
										unmountOnExit
										key={dealerLabel}
									>
										<SimpleTableHeader title="Custom Domain" add={(domain) => addCustomDomain(dealerLabel, domain)} />
										<RedirectsList
											data={getCustomDomainsForDealer(dealerLabel)}
											updateData={(updatedDomain, index) => updateCustomDomain(dealerLabel, index, updatedDomain)}
											remove={(index) => removeCustomDomain(dealerLabel, index)}
											simpleList
										/>
										{!isBrandPage && <SimpleTableHeader title="Spectra Domain" add={(domain) => addSpectraDomain(domain)} />}
										{!isBrandPage && <RedirectsList
											data={getSpectraDomainsForDealer(dealerLabel)}
											updateData={(updatedDomain, index) => updateSpectraDomain(dealerLabel, index, updatedDomain)}
											remove={(index) => removeSpectraDomain(dealerLabel, index)}
											simpleList
										/>}
									</Collapse>
								</>
							);
						})}
					</List>
				</div>
			</>
		);
	}
}

PageDealers.propTypes = {
	domainsData: PropTypes.any, //eslint-disable-line
	wwwRedirectData: PropTypes.any, //eslint-disable-line
	tlsRedirectData: PropTypes.any, //eslint-disable-line
	dealerDomainMappingData: PropTypes.any, //eslint-disable-line
	updateDomainInConfig: PropTypes.func,
	addDomainToConfig: PropTypes.func,
	removeDomainFromConfig: PropTypes.func,
};

const mapStateToProps = state => {
	const customConfig = getAppData(state, PATH_CUSTOM_CONFIG) || {};
	const domainsData = getAppData(state, PATH_DOMAINS) || [];
	const dealerDomainMappingData = getAppData(state, PATH_AMAG_DEALER_DOMAIN_MAPPING) || {};
	const wwwRedirectData = getAppData(state, PATH_REDIRECT_WWW);
	const tlsRedirectData = getAppData(state, PATH_REDIRECT_TLS);
	if (domainsData) {
		return {
			domainsData,
			dealerDomainMappingData,
			wwwRedirectData,
			tlsRedirectData,
			isBrandPage: Boolean(customConfig.brand),
		};
	}
	return undefined;
};

const mapDispatchToProps = dispatch => ({
	updateDomainInConfig: (index, newValue, path) =>
		dispatch(updateListItem(newValue, index, path)),
	discard: () => dispatch(discardAppData(PATH_DISCARD_AMAG_DEALERS)),
	addDomainToConfig: (domain, path) => dispatch(addDomain(domain, path)),
	removeDomainFromConfig: (index, path) => dispatch(removeDomain(index, path)),
});

const ConnectedPageDealers = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(PageDealers));

export { ConnectedPageDealers as PageDealers };
