import { pathOr } from 'ramda';
import { getSelectedKey } from './getSelectedKey';
import { getAppHasValidationErrors } from './getAppHasValidationErrors';

export function getIsPossibleToPublish(state) {
	const configKey = getSelectedKey(state);

	const configKeyToCheck = pathOr(
		null,
		['draftValidationState', configKey],
		state,
	);
	if (!configKeyToCheck) {
		return true;
	}
	const keys = Object.keys(configKeyToCheck);
	const appStatus = keys.map(key => getAppHasValidationErrors(state, key));
	const atLeastOneAppHasValidationErrors = appStatus.some(
		element => element === true,
	);

	return !atLeastOneAppHasValidationErrors;
}
