import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import {
	Grid,
	Typography,
} from '@material-ui/core';
import {
	addExcludedDomain,
	discardAppData,
	removeExcludedDomain,
	updateConfigDraftData,
} from '../../../actions/configActions';
import {
	PATH_AVAILABLE_DOMAINS,
	PATH_MAINTENANCE,
	PATH_MAINTENANCE_EXCLUDED_DOMAINS,
} from '../../../constants';
import { getAppData } from '../../../selectors/getAppData';
import { DiscardButton } from '../DiscardButton/DiscardButton';
import { ExcludedDomainsComponent } from '../ExcludedDomainsComponent/ExcludedDomainsComponent';
import { InputField } from '../InputField/InputField';
import { SwitchComponent } from '../Switch/SwitchComponent';
import * as css from './PageMaintenance.css';

function PageMaintenance({
	update,
	maintenanceData,
	discard,
	availableDomains,
	excludedDomains,
	addDomain,
	removeDomain,
}) {
	return (
		<Grid container>
			<Grid item sm={10}>
				<div className={css.contentHeader}>
					<Typography variant="h3">Maintenance Page</Typography>
				</div>
			</Grid>
			<Grid container item sm={2} alignItems="center">
				<Grid item>
					<DiscardButton text="Discard Changes" onClick={discard} />
				</Grid>
			</Grid>
			<SwitchComponent
				title="Maintenance Page"
				path="isActive"
				onChange={update}
				value={pathOr(false, ['isActive'], maintenanceData)}
			/>
			<InputField
				title="Remote Host"
				label="URL"
				path="remoteHost"
				onChange={update}
				value={pathOr('', ['remoteHost'], maintenanceData)}
				type="url"
			/>

			<ExcludedDomainsComponent
				title="Excluded Domains"
				availableDomains={availableDomains}
				excludedDomains={excludedDomains}
				listType="activeList"
				add={addDomain}
				remove={removeDomain}
			/>
			<ExcludedDomainsComponent
				title="Available Domains"
				availableDomains={availableDomains}
				excludedDomains={excludedDomains}
				listType="selectionList"
				add={addDomain}
				remove={removeDomain}
			/>
		</Grid>
	);
}

PageMaintenance.propTypes = {
	maintenanceData: PropTypes.any,
	availableDomains: PropTypes.any,
	excludedDomains: PropTypes.any,
	update: PropTypes.func,
	addDomain: PropTypes.func,
	removeDomain: PropTypes.func,
	discard: PropTypes.func,
};

const mapStateToProps = state => ({
	maintenanceData: getAppData(state, PATH_MAINTENANCE),
	availableDomains: getAppData(state, PATH_AVAILABLE_DOMAINS),
	excludedDomains: getAppData(state, PATH_MAINTENANCE_EXCLUDED_DOMAINS),
});

const mapDispatchToProps = dispatch => ({
	update: (newValue, path) =>
		dispatch(updateConfigDraftData(newValue, path, PATH_MAINTENANCE)),
	addDomain: domain =>
		dispatch(addExcludedDomain(domain, PATH_MAINTENANCE_EXCLUDED_DOMAINS)),
	removeDomain: domain =>
		dispatch(removeExcludedDomain(domain, PATH_MAINTENANCE_EXCLUDED_DOMAINS)),
	discard: () => dispatch(discardAppData(PATH_MAINTENANCE)),
});

const ConnectedPageMaintenance = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PageMaintenance);

export { ConnectedPageMaintenance as PageMaintenance };
