import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, withStyles } from '@material-ui/core';
import { TableListItem } from '../Table/TableLitsItem';
import { SimpleTableListItem } from '../Table/SimpleTableListItem';
import * as css from './RedirectsList.css';

const styles = theme => ({
	root: {
		marginTop: theme.spacing(3),
	},
});

function getList(data, updateData, remove) {
	if (data) {
		return data.map((item, index) => (
			<TableListItem
				// eslint-disable-next-line react/no-array-index-key
				key={index}
				updateData={updateData}
				index={index}
				from={item.from}
				to={item.to}
				removeRedirect={remove}
			/>
		));
	}
	return null;
}

function getSimpleList(data, updateData, remove) {
	if (data) {
		return data.map((item, index) => (
			<SimpleTableListItem
				key={item}
				updateData={updateData}
				data={item}
				index={index}
				removeListItem={remove}
			/>
		));
	}
	return null;
}

function RedirectsList({ classes, data, updateData, remove, simpleList }) {
	return (
		<div className={css.tableComponentContainer}>
			<Paper classes={{ root: classes.root }}>
				<Table>
					<TableBody>
						{simpleList
							? getSimpleList(data, updateData, remove)
							: getList(data, updateData, remove)}
					</TableBody>
				</Table>
			</Paper>
		</div>
	);
}

RedirectsList.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.any,
	updateData: PropTypes.func,
	simpleList: PropTypes.bool,
	remove: PropTypes.func,
};

const RedirectsListWithStyles = withStyles(styles)(RedirectsList);

const ConnectedPageRedirectsList = connect(
	null,
	null,
)(RedirectsListWithStyles);

export { ConnectedPageRedirectsList as RedirectsList };
