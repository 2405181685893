import PropTypes from 'prop-types';
import React from 'react';
import {
	Icon,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	withStyles,
} from '@material-ui/core';

const styles = theme => ({
	iconChanges: {
		color: '#fff',
		fontSize: '20px',
		marginLeft: '2px',
		marginRight: '16px',
	},
	iconEditorIsDirty: {
		color: '#fff',
		fontSize: '17px',
		marginLeft: '2px',
		marginRight: '16px',
	},
	listItem: {},
	highlight: {
		background: theme.palette.secondary.dark,
	},
});

function getIcon(classes, hasChanges, editorIsDirty, hasValidationErrors) {
	if (editorIsDirty) {
		return (
			<Tooltip title="Editor has unsaved changes.">
				<Icon
					aria-label="Editor has unsaved changes."
					classes={{ root: classes.iconEditorIsDirty }}
				>
					panorama_fish_eye
				</Icon>
			</Tooltip>
		);
	}

	if (hasValidationErrors) {
		return (
			<Tooltip title="Section has validation errors. Check if all required props are set in the JSON.">
				<Icon
					aria-label="Section has validation errors. Check if all required props are set in the JSON."
					classes={{ root: classes.iconChanges }}
				>
					warning
				</Icon>
			</Tooltip>
		);
	}

	if (hasChanges) {
		return (
			<Tooltip title="Section has unpublished changes.">
				<Icon
					aria-label="Section has unpublished changes."
					classes={{ root: classes.iconChanges }}
				>
					fiber_manual_record
				</Icon>
			</Tooltip>
		);
	}
	return <Icon />;
}

function getBackground(selected, classes) {
	if (selected) {
		return classes.highlight;
	}
	return classes.listItem;
}

function ConfigurationItem({
	classes,
	onClick,
	text,
	hasChanges,
	editorIsDirty,
	selected,
	hasValidationErrors,
}) {
	return (
		<ListItem
			classes={{ root: getBackground(selected, classes) }}
			button
			onClick={onClick}
		>
			<ListItemIcon>
				{getIcon(classes, hasChanges, editorIsDirty, hasValidationErrors)}
			</ListItemIcon>
			<ListItemText inset primary={text} />
		</ListItem>
	);
}

ConfigurationItem.propTypes = {
	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
	hasChanges: PropTypes.bool,
	editorIsDirty: PropTypes.bool,
	selected: PropTypes.bool,
	hasValidationErrors: PropTypes.bool,
};

const ConfigurationItemWithStyles = withStyles(styles)(ConfigurationItem);

export { ConfigurationItemWithStyles as ConfigurationItem };
