import React from 'react';
import AceEditorBase from 'react-ace';
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import 'ace-builds/src-noconflict/mode-json';
import "ace-builds/src-noconflict/theme-github";
import PropTypes from 'prop-types';

function handleAnnotations(errorArray, onErrors) {
	if (errorArray) {
		if (errorArray.length) {
			onErrors(true);
		} else {
			onErrors(false);
		}
	}
}

function AceEditor({ value, onChange, onErrors }) {
	return (
		<AceEditorBase
			value={value}
			theme="github"
			mode="json"
			height="100%"
			width="580px"
			onValidate={annotations => handleAnnotations(annotations, onErrors)}
			onChange={newValue => onChange(newValue)}
			setOptions={{
				enableBasicAutocompletion: true,
				enableLiveAutocompletion: true,
				enableSnippets: true,
				showLineNumbers: true,
				tabSize: 2,
			}}
		/>
	);
}

AceEditor.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	onErrors: PropTypes.func,
};

export { AceEditor };
