import { createTheme } from '@material-ui/core';

const themeTest = createTheme({
	palette: {
		primary: {
			main: '#5DADE2',
		},
		secondary: {
			main: '#85C1E9',
		},
		background: {
			default: '#5DADE2',
		},
	},
});

export default themeTest;
