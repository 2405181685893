.navigationBarContainer {
	background: #d5dbde;
	position: fixed;
	top: 66px;
	left: 0;
	width: 100%;
	z-index: 10;
}

.tenantSelectorItem {
	color: #000;
  text-transform: capitalize;
}
