.contentHeader {
	margin: 30px 0 0 30px;
}

.contentHeaderRemoveAction {
	text-transform: none;
}

.contentHeaderDeleteIcon {
	float: right;
}
