import { Checkbox, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
	tableCheck: {
		width: '15%',
	},
	tableText: {
		width: '85%',
	},
	selectItem: {
		color: '#000',
	},
	root: {
		'&$selected': {
			backgroundColor: 'rgba(0, 0, 0, 0)',
		},
	},
	selected: {},
	checkBox: {
		'&$checked': {
			color: 'white',
			backgroundColor: theme.palette.primary.main,
		},
	},
	checked: {},
	domainText: { fontSize: '17px' },
});

function handleClick(domain, add, remove, listType) {
	if (listType === 'selectionList') {
		add(domain);
	} else {
		remove(domain);
	}
}

function TableSelectItem({ classes, domain, listType, add, remove }) {
	return (
		<TableRow
			hover
			classes={{ root: classes.root, selected: classes.selected }}
			onClick={() => handleClick(domain, add, remove, listType)}
		>
			<TableCell
				scope="row"
				component="th"
				classes={{ root: classes.tableText }}
			>
				<Typography classes={{ root: classes.domainText }}>{domain}</Typography>
			</TableCell>
			<TableCell classes={{ root: classes.tableCheck }}>
				<Checkbox
					color="primary"
					checked={listType === 'activeList'}
					classes={{ colorPrimary: classes.checkBox, checked: classes.checked }}
				/>
			</TableCell>
		</TableRow>
	);
}

TableSelectItem.propTypes = {
	classes: PropTypes.object.isRequired,
	domain: PropTypes.string,
	listType: PropTypes.string,
	add: PropTypes.func,
	remove: PropTypes.func,
};

const TableComponentWithStyles = withStyles(styles)(TableSelectItem);

export { TableComponentWithStyles as TableSelectItem };
