import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import {
	Collapse,
	Grid,
	Icon,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	ExpandLess,
	ExpandMore,
} from '@material-ui/icons';
import {
	collapseSelected as collapseSelectedAction,
	configPageSelected as configPageSelectedAction,
} from '../../actions/uiActions';
import {
	OVERALL_APP,
	PATH_DOMAINS,
	PATH_MAINTENANCE,
	PATH_REDIRECTS,
	PATH_TRACKINGCODES,
} from '../../constants';
import { getAppsInfo } from '../../selectors/getAppsInfo';
import { getDataHasChanges } from '../../selectors/getDataHasChanges';
import { getEditorIsDirty } from '../../selectors/getEditorIsDirty';
import { getIsCollapseEnabled } from '../../selectors/getIsCollapseEnabled';
import { getSelectedConfigPage } from '../../selectors/getSelectedConfigPage';
import { getSelectedKey } from '../../selectors/getSelectedKey';
import { ConfigurationItem } from './ConfigurationItem';
import { getHasValidationErrors } from '../../selectors/getHasValidationErrors';
import { getAppHasValidationErrors } from '../../selectors/getAppHasValidationErrors';
import warningIcon from './WarningIcon.svg';

const styles = theme => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
	collapseWrapperInner: {
		background: theme.palette.secondary.main,
	},
	collapseWrapperInnerSelected: {
		background: theme.palette.secondary.dark,
	},
	listOverflow: {
		height: 'calc(100% - 130px)',
		overflow: 'auto',
	},
	iconChanges: {
		color: '#fff',
		fontSize: '20px',
		marginLeft: '2px',
	},
	iconExpand: {
		color: '#fff',
	},
	iconHelp: {
		color: '#fff',
		'margin-right': '8px',
	},
	highlightColor: {
		background: theme.palette.secondary.dark,
	},
	warningScreen: {
		height: 'calc(100% - 130px)',
	},
	warningText: {
		textAlign: 'center',
		color: '#fff',
		marginTop: '50px',
	},
	warningIcon: {
		marginTop: '50px',
	},
});

function handleClick(id, selectCollapse, selectedConfigTenantKey) {
	selectCollapse(id, selectedConfigTenantKey);
}

function getIcon(state, appName, isCollapseEnabled, classes) {
	if (
		getAppHasValidationErrors(state, appName) &&
		!isCollapseEnabled(appName)
	) {
		return (
			<Tooltip title="App has validation errors.">
				<Icon
					aria-label="App has validation errors."
					classes={{ root: classes.iconChanges }}
				>
					warning
				</Icon>
			</Tooltip>
		);
	}
	if (getDataHasChanges(state, null, appName) && !isCollapseEnabled(appName)) {
		return (
			<Tooltip title="App has unpublished changes.">
				<Icon
					aria-label="App has unpublished changes."
					classes={{ root: classes.iconChanges }}
				>
					fiber_manual_record
				</Icon>
			</Tooltip>
		);
	}
	return <Icon />;
}

function AppList({
	classes,
	appsInfo,
	selectedTenant,
	selectedConfigTenantKey,
	selectConfigPage,
	selectCollapse,
	isCollapseEnabled,
	state,
}) {
	if (!appsInfo) {
		return null;
	}

	if (isEmpty(appsInfo)) {
		return (
			<div className={classes.warningScreen}>
				<Grid
					container
					direction="column"
					alignItems="center"
					justifyContent="space-around"
				>
					<Typography
						className={classes.warningText}
						variant="subtitle1"
					>
						Whoooops...
					</Typography>
					<img
						className={classes.warningIcon}
						src={warningIcon}
						alt="Laser Lemon Logo"
					/>
					<Typography
						className={classes.warningText}
						variant="subtitle2"
					>
						Your selected configuration seems to be missing!
					</Typography>
				</Grid>
			</div>
		);
	}
	return (
		<List
			classes={{ root: classes.listOverflow }}
			component="nav"
			subheader={
				<ListSubheader component="div">{selectedTenant}</ListSubheader>
			}
		>
			{appsInfo.map(appInfo => {
				const { appName, appKey, displayName, appDescription } = appInfo;
				const canHaveCustomDomain = [
					'amag',
					'amagSeat',
					'amagSkoda',
					'amagAudi',
					'amagRetailer',
					'dealerSites',
				].includes(appKey);

				return (
					<Fragment key={appName}>
						<ListItem
							button
							onClick={() =>
								handleClick(appName, selectCollapse, selectedConfigTenantKey)
							}
						>
							<ListItemIcon>
								{getIcon(state, appName, isCollapseEnabled, classes)}
							</ListItemIcon>
							<ListItemText inset primary={displayName || appKey} />
							{appDescription && (
								<Tooltip title={appDescription}>
									<Icon
										aria-label={appDescription}
										classes={{ root: classes.iconHelp }}
									>
										help_outline
									</Icon>
								</Tooltip>
							)}

							{isCollapseEnabled(appName) ? (
								<ExpandLess classes={{ root: classes.iconExpand }} />
							) : (
								<ExpandMore classes={{ root: classes.iconExpand }} />
							)}
						</ListItem>
						<Collapse
							classes={{ wrapperInner: classes.collapseWrapperInner }}
							in={isCollapseEnabled(appName)}
							timeout="auto"
							unmountOnExit
						>
							<List component="div" disablePadding>
								<ConfigurationItem
									selected={getSelectedConfigPage(state, appName) === 'domains'}
									text="Domains"
									onClick={() =>
										selectConfigPage(
											appName,
											'domains',
											selectedConfigTenantKey,
										)
									}
									hasChanges={getDataHasChanges(state, PATH_DOMAINS, appName)}
									hasValidationErrors={getHasValidationErrors(
										state,
										PATH_DOMAINS,
										appName,
									)}
								/>
								<ConfigurationItem
									selected={
										getSelectedConfigPage(state, appName) === 'redirect'
									}
									text="Redirects"
									onClick={() =>
										selectConfigPage(
											appName,
											'redirect',
											selectedConfigTenantKey,
										)
									}
									hasChanges={getDataHasChanges(state, PATH_REDIRECTS, appName)}
									hasValidationErrors={getHasValidationErrors(
										state,
										PATH_REDIRECTS,
										appName,
									)}
								/>
								<ConfigurationItem
									selected={
										getSelectedConfigPage(state, appName) === 'maintenance'
									}
									text="Maintenance Page"
									onClick={() =>
										selectConfigPage(
											appName,
											'maintenance',
											selectedConfigTenantKey,
										)
									}
									hasChanges={getDataHasChanges(
										state,
										PATH_MAINTENANCE,
										appName,
									)}
									hasValidationErrors={getHasValidationErrors(
										state,
										PATH_MAINTENANCE,
										appName,
									)}
								/>
								<ConfigurationItem
									selected={
										getSelectedConfigPage(state, appName) === 'trackingCodes'
									}
									text="Tracking Codes"
									onClick={() =>
										selectConfigPage(
											appName,
											'trackingCodes',
											selectedConfigTenantKey,
										)
									}
									hasChanges={getDataHasChanges(
										state,
										PATH_TRACKINGCODES,
										appName,
									)}
									hasValidationErrors={getHasValidationErrors(
										state,
										PATH_TRACKINGCODES,
										appName,
									)}
								/>
								{canHaveCustomDomain && (
									<ConfigurationItem
										selected={
											getSelectedConfigPage(state, appName) === 'dealers'
										}
										text="Dealers"
										onClick={() =>
											selectConfigPage(
												appName,
												'dealers',
												selectedConfigTenantKey,
											)
										}
										hasChanges={getDataHasChanges(state, null, appName)}
									/>
								)}
								<ConfigurationItem
									selected={
										getSelectedConfigPage(state, appName) === 'jsonEditor'
									}
									text="JSON Editor"
									onClick={() =>
										selectConfigPage(
											appName,
											'jsonEditor',
											selectedConfigTenantKey,
										)
									}
									editorIsDirty={getEditorIsDirty(state, appName)}
									hasChanges={getDataHasChanges(state, null, appName)}
								/>
							</List>
						</Collapse>
					</Fragment>
				);
			})}

			<Fragment key={OVERALL_APP}>
				<ListItem
					button
					onClick={() =>
						handleClick(OVERALL_APP, selectCollapse, selectedConfigTenantKey)
					}
				>
					<ListItemIcon>
						{getIcon(state, OVERALL_APP, isCollapseEnabled, classes)}
					</ListItemIcon>
					<ListItemText inset primary="Overall Apps" />
					{isCollapseEnabled(OVERALL_APP) ? (
						<ExpandLess classes={{ root: classes.iconExpand }} />
					) : (
						<ExpandMore classes={{ root: classes.iconExpand }} />
					)}
				</ListItem>
				<Collapse
					classes={{ wrapperInner: classes.collapseWrapperInner }}
					in={isCollapseEnabled(OVERALL_APP)}
					timeout="auto"
					unmountOnExit
				>
					<List component="div" disablePadding>
						<ConfigurationItem
							selected={
								getSelectedConfigPage(state, OVERALL_APP) === 'jsonEditor'
							}
							text="Tenant JSON Editor"
							onClick={() =>
								selectConfigPage(
									OVERALL_APP,
									'jsonEditor',
									selectedConfigTenantKey,
								)
							}
							editorIsDirty={getEditorIsDirty(state, OVERALL_APP)}
							hasChanges={getDataHasChanges(state, null, OVERALL_APP)}
						/>
					</List>
				</Collapse>
			</Fragment>
		</List>
	);
}

AppList.propTypes = {
	classes: PropTypes.object.isRequired,
	appsInfo: PropTypes.array,
	selectedTenant: PropTypes.string,
	selectedConfigTenantKey: PropTypes.string,
	selectConfigPage: PropTypes.func.isRequired,
	selectCollapse: PropTypes.func.isRequired,
	isCollapseEnabled: PropTypes.func.isRequired,
	state: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		appsInfo: getAppsInfo(state),
		selectedTenant: state.ui.selectedTenant,
		selectedConfigTenantKey: getSelectedKey(state),
		isCollapseEnabled: appKey => getIsCollapseEnabled(state, appKey),
		state,
		draftState: state.draftValidationState,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		selectConfigPage: (appKey, configPageKey, configKey) =>
			dispatch(configPageSelectedAction(appKey, configPageKey, configKey)),
		selectCollapse: (selectedCollapse, configKey) =>
			dispatch(collapseSelectedAction(selectedCollapse, configKey)),
	};
}

const EnhancedAppList = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(AppList));

export { EnhancedAppList as AppList };
