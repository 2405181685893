import { createTheme } from '@material-ui/core';

const themeUndefined = createTheme({
	palette: {
		primary: {
			main: '#d5dbde',
		},
		secondary: {
			main: '#d5dbde',
		},
		background: {
			default: '#d5dbde',
		},
	},
});

export default themeUndefined;
