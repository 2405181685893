import { createTheme } from '@material-ui/core';

const themePreprod = createTheme({
	palette: {
		primary: {
			main: '#6D4C41',
		},
		secondary: {
			main: '#A1887F',
		},
		background: {
			default: '#6D4C41',
		},
	},
});

export default themePreprod;
