import React from 'react';
import PropTypes from 'prop-types';
import {
	Grid,
	Typography,
} from '@material-ui/core';
import { SelectionList } from '../SelectionList/SelectionList';
import * as css from './ExcludedDomainsComponent.css';

function ExcludedDomainsComponent({
	title,
	availableDomains,
	excludedDomains,
	listType,
	add,
	remove,
}) {
	return (
		<div className={css[listType]}>
			<Grid container alignItems="flex-start">
				<Grid item sm={3}>
					<div className={css.excludedDomainsContainerLabel}>
						<Typography variant="subtitle1">{title}</Typography>
					</div>
				</Grid>
				<Grid item sm={8}>
					<SelectionList
						availableDomains={availableDomains}
						excludedDomains={excludedDomains}
						listType={listType}
						add={add}
						remove={remove}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

ExcludedDomainsComponent.propTypes = {
	title: PropTypes.string,
	availableDomains: PropTypes.array,
	excludedDomains: PropTypes.array,
	listType: PropTypes.string,
	add: PropTypes.func,
	remove: PropTypes.func,
};

export { ExcludedDomainsComponent };
