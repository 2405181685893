.sideBar {
	position: fixed;
	width: 25%;
	height: 100vh;
}

.content {
	margin-left: 25% !important;
	height: 100vh;
}
