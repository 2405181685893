.header {
	background-color: #333333;
 	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1100;
}

.iconContainer {
	margin: 10px;
}

.icon {
	overflow: visible;
}

.icon .active {
	animation: spinning 3s infinite;
	transform-origin: 20px 20px;
}

@keyframes spinning {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(360deg);
	}
}
