import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	FormControl,
	Icon,
	Input,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	withStyles,
} from '@material-ui/core';
import * as css from './TableHeaderComponent.css';

const styles = theme => ({
	root: {
		marginTop: theme.spacing(3),
	},
	iconAddBox: {
		color: theme.palette.primary.main,
		fontSize: 40,
		float: 'right',
		marginLeft: '30px',
	},
	tableCellNormal: {
		width: '30%',
	},
	tableCellIcon: {
		width: '10%',
	},
	addButtonIcon: {
		fontSize: '40px',
	},
	button: {
		padding: '8px',
		minWidth: '40px',
	},
	selectItem: {
		color: '#000',
	},
});

class TableHeaderComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			from: '',
			to: '',
		};
	}

	render() {
		const { classes, add } = this.props;
		const { from, to } = this.state;

		const handleChange = (event, prop) => {
			const newValue = event.target.value;
			const prevState = this.state;
			this.setState({ ...prevState, [prop]: newValue });
		};

		const handleOnClick = () => {
			add(from, to);
			const prevState = this.state;
			this.setState({
				...prevState,
				from: '',
				to: '',
			});
		};

		return (
			<div className={css.tableHeaderComponentContainer}>
				<Paper classes={{ root: classes.root }}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell classes={{ root: classes.tableCellNormal }}>
									<Typography variant="subtitle1">From</Typography>
								</TableCell>
								<TableCell classes={{ root: classes.tableCellNormal }}>
									<Typography variant="subtitle1">To</Typography>
								</TableCell>
								<TableCell classes={{ root: classes.tableCellNormal }}>
									<Typography variant="subtitle1">Status Code</Typography>
								</TableCell>
								<TableCell classes={{ root: classes.tableCellIcon }} />
							</TableRow>
						</TableBody>
						<TableBody>
							<TableRow>
								<TableCell classes={{ root: classes.tableCellNormal }}>
									<FormControl fullWidth>
										<Input
											value={from}
											onChange={event => handleChange(event, 'from')}
										/>
									</FormControl>
								</TableCell>
								<TableCell classes={{ root: classes.tableCellNormal }}>
									<FormControl fullWidth>
										<Input
											value={to}
											onChange={event => handleChange(event, 'to')}
										/>
									</FormControl>
								</TableCell>
								<TableCell classes={{ root: classes.tableCellNormal }}>
									<FormControl fullWidth disabled>
										<Select
											value={0}
											inputProps={{
												name: 'statusCode',
											}}
										>
											<MenuItem
												classes={{ root: classes.selectItem }}
												value={0}
											>
												None
											</MenuItem>
											<MenuItem
												classes={{ root: classes.selectItem }}
												value={301}
											>
												301 - Moved Permanently
											</MenuItem>
											<MenuItem
												classes={{ root: classes.selectItem }}
												value={302}
											>
												302 - Found
											</MenuItem>
											<MenuItem
												classes={{ root: classes.selectItem }}
												value={303}
											>
												303 - See Other
											</MenuItem>
										</Select>
									</FormControl>
								</TableCell>
								<TableCell classes={{ root: classes.tableCellIcon }}>
									<Button
										classes={{ root: classes.button }}
										color="primary"
										aria-label="add redirect"
										onClick={() => handleOnClick()}
									>
										<Icon classes={{ root: classes.addButtonIcon }}>
											add_box
										</Icon>
									</Button>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Paper>
			</div>
		);
	}
}

TableHeaderComponent.propTypes = {
	classes: PropTypes.object,
	add: PropTypes.func,
};

const TableHeaderComponentWS = withStyles(styles)(TableHeaderComponent);

export { TableHeaderComponentWS as TableHeaderComponent };
