.contentHeader {
	margin: 30px 0 0 30px;
}

.contentHeaderRemoveAction {
	text-transform: none;
}

.contentHeaderDeleteIcon {
	float: right;
}
.activeRedirectsContainer {
	width: 100%;
	margin: 30px;
	margin-left: 30px;
}
