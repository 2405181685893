
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Button,
	Grid,
	Typography,
	withStyles,
} from '@material-ui/core';
import { discardAppData } from '../../../actions/configActions';
import {
	formatData,
	loadOriginalData,
	resetDataToDraft,
	saveChanges,
	updateEditorData,
	validateEditor,
} from '../../../actions/editorActions';
import { getDataHasChanges } from '../../../selectors/getDataHasChanges';
import { getEditorData } from '../../../selectors/getEditorData';
import { getEditorIsDirty } from '../../../selectors/getEditorIsDirty';
import { getHasEditorErrors } from '../../../selectors/getHasEditorErrors';
import { JSONEditor } from '../../JSONEditor';
import { DiscardButton } from '../DiscardButton/DiscardButton';
import * as css from './PageEditor.css';

const styles = () => ({
	saveButton: {
		color: '#fff',
		minWidth: '170px',
	},
	formatButton: {
		color: '#fff',
		minWidth: '170px',
	},
	loadOriginalButton: {
		minWidth: '170px',
	},
	resetButton: {
		minWidth: '170px',
	},
	statusSign: {
		fontSize: '40px',
		float: 'right',
		marginRight: '160px',
	},
});

// function getStatusMessage(classes, hasChanges) {
// 	if (hasChanges) {
// 		return (
// 			<Icon classes={{ root: classes.statusSign }} color="primary">
// 				warning
// 			</Icon>
// 		);
// 	}
// }

function PageEditor({
	classes,
	editorData,
	updateEditor,
	save,
	onErrors,
	hasErrors,
	hasChanges,
	formatJson,
	loadOriginal,
	resetChanges,
	state,
}) {
	return (
		<Grid container className={css.container}>
			<Grid container className={css.titleContainer}>
				<Grid item sm={10}>
					<div className={css.contentHeader}>
						<Typography variant="h3">JSON Editor</Typography>
					</div>
				</Grid>
				<Grid container item sm={2} alignItems="center">
					<Grid item>
						{/* {getStatusMessage(hasChanges)} */}
						<DiscardButton
							onClick={resetChanges}
							disabled={!hasChanges}
							text="Discard Changes"
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid container className={css.contentContainer}>
				<Grid item sm={7}>
					<div className={css.editorContainer}>
						<JSONEditor
							value={editorData}
							onChange={updateEditor}
							onErrors={onErrors}
						/>
					</div>
				</Grid>
				<Grid item sm={5}>
					<div className={css.buttonContainer}>
						<Button
							classes={{ root: classes.formatButton }}
							onClick={formatJson}
							color="secondary"
							variant="contained"
							disabled={hasErrors || !hasChanges}
							size="large"
						>
							Format JSON
						</Button>
					</div>
					<div className={css.buttonContainer}>
						<Button
							classes={{ root: classes.loadOriginalButton }}
							onClick={loadOriginal}
							color="primary"
							size="large"
							disabled={!getDataHasChanges(state)}
						>
							Load Original
						</Button>
					</div>
					<div className={css.buttonContainer}>
						<Button
							classes={{ root: classes.saveButton }}
							onClick={save}
							color="primary"
							variant="contained"
							disabled={hasErrors || !hasChanges}
							size="large"
						>
							Save Changes
						</Button>
						{/* {getStatusMessage(classes, hasChanges)} */}
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
}

PageEditor.propTypes = {
	classes: PropTypes.object.isRequired,
	editorData: PropTypes.string.isRequired,
	updateEditor: PropTypes.func.isRequired,
	save: PropTypes.func.isRequired,
	onErrors: PropTypes.func.isRequired,
	hasErrors: PropTypes.bool,
	hasChanges: PropTypes.bool,
	formatJson: PropTypes.func.isRequired,
	loadOriginal: PropTypes.func.isRequired,
	resetChanges: PropTypes.func.isRequired,
	state: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	editorData: getEditorData(state),
	hasErrors: getHasEditorErrors(state),
	hasChanges: getEditorIsDirty(state),
	state,
});

const mapDispatchToProps = dispatch => ({
	save: () => dispatch(saveChanges()),
	updateEditor: newValue => dispatch(updateEditorData(newValue)),
	discardConfigDraft: () => dispatch(discardAppData()),
	onErrors: hasErrors => dispatch(validateEditor(hasErrors)),
	formatJson: () => dispatch(formatData()),
	loadOriginal: () => dispatch(loadOriginalData()),
	resetChanges: () => dispatch(resetDataToDraft()),
});

const ConnectedPageEditor = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PageEditor);

const PageEditorWithStyles = withStyles(styles)(ConnectedPageEditor);

export { PageEditorWithStyles as PageEditor };
