import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogTitle,
	Grid,
	Typography,
	withStyles,
} from '@material-ui/core';
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import 'ace-builds/src-noconflict/mode-json';

import { diff as DiffEditor } from 'react-ace';
import * as css from './PreviewModal.css';

const styles = theme => ({
	codeMarker: {
		backgroundColor: '#FFF677',
		position: 'absolute',
		zIndex: 20,
	},
	dialogContainer: {
		maxWidth: '1400px',
		maxHeight: 'calc(100% - 40px)',
	},
	buttonPublish: {
		margin: '15px 50px 15px 50px',
		width: '250px',
		height: '50px',
	},
	buttonCancel: {
		margin: '15px 50px 15px 50px',
	},
	statusDrawer: {
		backgroundColor: theme.palette.primary.main,
	},
	statusText: {
		margin: '10px 30px 10px 30px',
	},
	editorDescriptionContainer: {},
	editorDescription: {
		color: 'black',
		marginTop: '10px',
		marginBottom: '10px',
	},
	dialogTitle: {
		padding: '10px',
	},
});

function handlePublish(update, newConfigData, onClose) {
	update(newConfigData);
	onClose();
}

function PreviewModal({
	classes,
	open,
	onClose,
	originalData,
	draftData,
	tenant,
	env,
	update,
}) {
	return (
		<Dialog
			classes={{ paperWidthSm: classes.dialogContainer }}
			open={open}
		>
			<div>
				<Grid
					container
					justifyContent="center"
				>
					<DialogTitle
						classes={{ root: classes.dialogTitle }}
						id="simple-dialog-title"
					>
						Check your changes for...
					</DialogTitle>
				</Grid>
				<Grid
					className={classes.statusDrawer}
					container
					justifyContent="center"
				>
					<Typography className={classes.statusText} variant="subtitle2">
						{tenant}
					</Typography>
					<Typography className={classes.statusText} variant="subtitle2">
						{env}
					</Typography>
				</Grid>
				<Grid
					className={classes.editorDescriptionContainer}
					container
					justifyContent="space-around"
				>
					<Typography
						className={classes.editorDescription}
						variant="subtitle1"
					>
						Original JSON
					</Typography>
					<Typography
						className={classes.editorDescription}
						variant="subtitle1"
					>
						New JSON
					</Typography>
				</Grid>
				<Grid
					container
					justifyContent="center"
				>
					<div className={css.editorContainer}>
						<DiffEditor
							value={[originalData || '', draftData || '']}
							height="500px"
							width="1300px"
							mode="json"
							theme="github"
							readOnly
						/>
					</div>
				</Grid>
				<Grid
					container
					justifyContent="space-between"
				>
					<Button
						classes={{ root: classes.buttonCancel }}
						variant="outlined"
						size="large"
						onClick={() => onClose()}
					>
						Cancel
					</Button>

					<Button
						classes={{ root: classes.buttonPublish }}
						variant="contained"
						size="large"
						color="primary"
						onClick={() => handlePublish(update, draftData, onClose)}
					>
						Publish
					</Button>
				</Grid>
			</div>
		</Dialog>
	);
}

PreviewModal.propTypes = {
	classes: PropTypes.object,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	originalData: PropTypes.any,
	draftData: PropTypes.any,
	tenant: PropTypes.string,
	env: PropTypes.string,
	update: PropTypes.func,
};

const PreviewModalWS = withStyles(styles)(PreviewModal);

export { PreviewModalWS as PreviewModal };
