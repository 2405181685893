import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSelectedConfigPage } from '../../selectors/getSelectedConfigPage';
import { PageMaintenance } from './contentPages/PageMaintenance';
import { PageRedirects } from './contentPages/PageRedirects';
import { PageTrackingCodes } from './contentPages/PageTrackingCodes';
import { PageEditor } from './contentPages/PageEditor';
import { PageDomains } from './contentPages/PageDomains';
import { PageDealers } from './contentPages/PageDealers';
import * as css from './Content.css';

function getActiveContentPage(contentPageActive) {
	switch (contentPageActive) {
		case 'domains':
			return <PageDomains />;
		case 'redirect':
			return <PageRedirects />;
		case 'maintenance':
			return <PageMaintenance />;
		case 'trackingCodes':
			return <PageTrackingCodes />;
		case 'dealers':
			return <PageDealers />;
		case 'jsonEditor':
			return <PageEditor />;
		default:
			return undefined;
	}
}

function Content({ selectedConfigPage }) {
	return (
		<div className={css.contentContainer}>
			<div className={css.activeContentContainer}>
				{getActiveContentPage(selectedConfigPage)}
			</div>
		</div>
	);
}

Content.propTypes = {
	selectedConfigPage: PropTypes.string,
};

const mapStateToProps = state => ({
	selectedConfigPage: getSelectedConfigPage(state),
});

const connectedContent = connect(mapStateToProps)(Content);

export { connectedContent as Content };
