import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	Grid,
	Typography
} from '@material-ui/core';
import {
	addDomain,
	discardAppData,
	removeDomain,
	updateListItem,
} from '../../../actions/configActions';
import { PATH_DOMAINS } from '../../../constants';
import { getAppData } from '../../../selectors/getAppData';
import { DiscardButton } from '../DiscardButton/DiscardButton';
import { RedirectsList } from '../RedirectsList/RedirectsList';
import { SimpleTableHeader } from '../TableHeader/SimpleTableHeader';
import * as css from './PageDomains.css';

function PageDomains({ domainsData, updateDomains, discard, add, remove }) {
	return (
		<Grid container>
			<Grid item sm={10}>
				<div className={css.contentHeader}>
					<Typography variant="h3">Domains</Typography>
				</div>
			</Grid>
			<Grid container item sm={2} alignItems="center">
				<Grid item>
					<DiscardButton text="Discard Changes" onClick={discard} />
				</Grid>
			</Grid>
			<SimpleTableHeader title="Domain" add={add} />
			<RedirectsList
				data={domainsData}
				updateData={updateDomains}
				remove={remove}
				simpleList
			/>
		</Grid>
	);
}

PageDomains.propTypes = {
	domainsData: PropTypes.any, //eslint-disable-line
	updateDomains: PropTypes.func,
	discard: PropTypes.func,
	add: PropTypes.func,
	remove: PropTypes.func,
};

const mapStateToProps = (state) => {
	const appData = getAppData(state, PATH_DOMAINS);
	if (appData) {
		return {
			domainsData: appData,
		};
	}
	return undefined;
};

const mapDispatchToProps = (dispatch) => ({
	updateDomains: (newValue, index) =>
		dispatch(updateListItem(newValue, index, PATH_DOMAINS)),
	discard: () => dispatch(discardAppData(PATH_DOMAINS)),
	add: (domain) => dispatch(addDomain(domain, PATH_DOMAINS)),
	remove: (index) => dispatch(removeDomain(index, PATH_DOMAINS)),
});

const ConnectedPageDomains = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PageDomains);

export { ConnectedPageDomains as PageDomains };
