import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	AppBar,
	FormControl,
	Grid,
	MenuItem,
	Paper,
	Select,
	Tab,
	Tabs,
	withStyles,
} from '@material-ui/core';
import { fetchConfigData } from '../../actions/configActions';
import { envSelected, tenantSelected } from '../../actions/uiActions';
import { ENVIRONMENTS, LOCAL, TENANTS } from '../../constants';
import * as css from './NavigationBar.css';

const styles = (theme) => ({
	tabsRoot: {
		background: '#d5dbde',
	},

	tabLabel: {
		fontSize: '1rem',
	},
	tabRoot: {
		height: '65px',
	},
	tabsIndicator: {
		height: '5px',
		boxShadow: `0px -2px 20px 0px ${theme.palette.primary.main}`,
	},
	appBarRoot: {
		boxShadow: '',
	},

	formControlPaper: {
		width: '100%',
		margin: '0',
		padding: '5px 10px 5px 10px',
		height: '64px',
		borderRadius: '0',
		zIndex: '1100px',
	},
	formControl: {
		minWidth: '100%',
		float: 'left',
	},
	select: {
		marginLeft: '10px',
		height: '45px',
		paddingBottom: '0',
		width: '100%',
	},
});

function handleEnvChange(value, selectedEnv, makeConfigDataRequest) {
	selectedEnv(value);
	makeConfigDataRequest();
}

function handleTenantChange(event, selectedTenant, makeConfigDataRequest) {
	selectedTenant(event.target.value);
	makeConfigDataRequest();
}

function NavigationBar({
	classes,
	tabValue,
	tenantValue,
	selectedEnv,
	selectedTenant,
	makeConfigDataRequest,
}) {
	return (
		<div className={css.navigationBarContainer}>
			<Grid container>
				<Grid container item sm={3}>
					<Paper classes={{ root: classes.formControlPaper }} elevation={24}>
						<FormControl fullWidth classes={{ root: classes.formControl }}>
							<Select
								classes={{ root: classes.select }}
								value={tenantValue || ''}
								onChange={(event) =>
									handleTenantChange(
										event,
										selectedTenant,
										makeConfigDataRequest,
									)
								}
								inputProps={{
									name: 'tenant',
									id: 'tenant-selector',
								}}
							>
								{TENANTS.filter((tenant) => tenant.envs.includes(tabValue)).map(
									(tenant) => (
										<MenuItem value={tenant.name} key={tenant.name}>
											<p className={css.tenantSelectorItem}>{tenant.name}</p>
										</MenuItem>
									),
								)}
							</Select>
						</FormControl>
					</Paper>
				</Grid>

				<Grid item sm={9}>
					<AppBar elevation={0} position="static" color="default">
						<Tabs
							value={tabValue}
							onChange={(event, value) =>
								handleEnvChange(value, selectedEnv, makeConfigDataRequest)
							}
							indicatorColor="primary"
							textColor="primary"
							classes={{
								root: classes.tabsRoot,
								indicator: classes.tabsIndicator,
							}}
						>
							{ENVIRONMENTS.map((env) => (
								<Tab
									classes={{
										root: classes.tabRoot,
										selected: classes.tabLabel,
									}}
									label={env}
									value={env}
									key={env}
								/>
							))}
							{process.env.SHOW_LOCAL_ENV === 'true' && (
								<Tab
									classes={{
										root: classes.tabRoot,
										selected: classes.tabLabel,
									}}
									label={LOCAL}
									value={LOCAL}
								/>
							)}
						</Tabs>
					</AppBar>
				</Grid>
			</Grid>
		</div>
	);
}

NavigationBar.propTypes = {
	classes: PropTypes.object.isRequired,
	tabValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	tenantValue: PropTypes.string,
	selectedEnv: PropTypes.func.isRequired,
	selectedTenant: PropTypes.func.isRequired,
	makeConfigDataRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	tabValue: state.ui.selectedEnvironment,
	tenantValue: state.ui.selectedTenant,
});

const mapDispatchToProps = (dispatch) => ({
	selectedEnv: (env) => dispatch(envSelected(env)),
	selectedTenant: (tenant) => dispatch(tenantSelected(tenant)),
	makeConfigDataRequest: () => dispatch(fetchConfigData()),
});

const NavBarWitStyles = withStyles(styles)(NavigationBar);

const ConnectedNavigationBar = connect(
	mapStateToProps,
	mapDispatchToProps,
)(NavBarWitStyles);

export { ConnectedNavigationBar as NavigationBar };
