import { createTheme } from '@material-ui/core';

const themeCH1Prod = createTheme({
	palette: {
		primary: {
			main: '#F58441',
		},
		secondary: {
			main: '#FF9F66',
		},
		background: {
			default: '#F58441',
		},
	},
});

export default themeCH1Prod;
