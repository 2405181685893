.contentHeader {
	margin: 30px;
}

.editorContainer {
	margin-left: 70px;
	position: relative;
	height: 100%;
}

.editorBottomButtonsContainer {
	margin-left: 50px;
	margin-top: 10px;
}

.buttonContainer {
	margin-bottom: 50px;
}

.container {
	height: 100%;
}

.titleContainer {
  height: 15%;
}

.contentContainer {
  height: 85%;
  padding: 2% 0;
}
