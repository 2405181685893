import { pathOr } from 'ramda';
import { getSelectedKey } from './getSelectedKey';
import { getSelectedAppKey } from './getSelectedAppKey';
import { getAppData } from './getAppData';

export function getEditorData(state) {
	const configKey = getSelectedKey(state);
	const selectedApp = getSelectedAppKey(state);
	return pathOr(
		JSON.stringify(getAppData(state), null, '\t'),
		['editorState', configKey, selectedApp, 'data'],
		state,
	);
}
