.sidebarContainer {
	height: 100vh;
	position: relative;
	overflow: hidden;
	padding-top: 130px;
}
.previewButton {
	position: absolute;
	bottom: 75px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	text-align: center;
}
.deleteCacheButton {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	text-align: center;
}

.buttonHr {
	margin: 0px 0px 10px 0px;
	border-top: 2px solid #e6e6e6;
}
