import React from 'react';
import PropTypes from 'prop-types';
import { AceEditor } from './AceEditor';

function JSONEditor({ value, onChange, onErrors }) {
	return (
		<AceEditor value={value} onChange={onChange} onErrors={onErrors} />
	);
}

JSONEditor.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	onErrors: PropTypes.func,
};

export { JSONEditor };
