import { createTheme } from '@material-ui/core';

const themeCH1Test = createTheme({
	palette: {
		primary: {
			main: '#279393',
		},
		secondary: {
			main: '#43A8A8',
		},
		background: {
			default: '#279393',
		},
	},
});

export default themeCH1Test;
