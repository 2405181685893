import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Icon,
	Typography,
} from '@material-ui/core';
import * as css from './DiscardButton.css';

function DiscardButton({ onClick, text, disabled }) {
	return (
		<Button onClick={onClick} disabled={disabled}>
			<Typography
				className={css.contentHeaderRemoveAction}
				color="textSecondary"
				variant="button"
			>
				{text}
			</Typography>
			<Icon className={css.contentHeaderDeleteIcon}>delete_outline</Icon>
		</Button>
	);
}

DiscardButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

export { DiscardButton };
