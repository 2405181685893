import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	FormControl,
	Icon,
	Input,
	TableCell,
	TableRow,
	withStyles,
} from '@material-ui/core';

const styles = (theme) => ({
	root: {
		marginTop: theme.spacing(3),
	},
	tableCellNormal: {
		width: '90%',
	},
	tableCellIcon: {
		width: '10%',
	},
	addButtonIcon: {
		fontSize: '40px',
	},
	button: {
		padding: '8px',
		minWidth: '40px',
	},
	selectItem: {
		color: '#000',
	},
});

function onChange(event, index, path, updateData) {
	const newValue = event.target.value;
	updateData(newValue, index, path);
}

function SimpleTableListItem({
	classes,
	data,
	index,
	updateData,
	removeListItem,
}) {
	return (
		<TableRow>
			<TableCell classes={{ root: classes.tableCellNormal }}>
				<FormControl fullWidth>
					<Input
						error={data === ''}
						value={data}
						onChange={(event) => onChange(event, index, 'from', updateData)}
					/>
				</FormControl>
			</TableCell>
			<TableCell classes={{ root: classes.tableCellIcon }}>
				<Button
					classes={{ root: classes.button }}
					color="primary"
					aria-label="add redirect"
					onClick={() => removeListItem(index)}
				>
					<Icon classes={{ root: classes.addButtonIcon }}>
						indeterminate_check_box
					</Icon>
				</Button>
			</TableCell>
		</TableRow>
	);
}

SimpleTableListItem.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.string,
	index: PropTypes.number,
	updateData: PropTypes.func,
	removeListItem: PropTypes.func,
};

const SimpleTableComponentWithStyles = withStyles(styles)(SimpleTableListItem);

export { SimpleTableComponentWithStyles as SimpleTableListItem };
