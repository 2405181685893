import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	FormControl,
	Icon,
	Input,
	MenuItem,
	Select,
	TableCell,
	TableRow,
	withStyles
} from '@material-ui/core';

const styles = theme => ({
	root: {
		marginTop: theme.spacing(3),
	},
	tableCellNormal: {
		width: '30%',
	},
	tableCellIcon: {
		width: '10%',
	},
	addButtonIcon: {
		fontSize: '40px',
	},
	button: {
		padding: '8px',
		minWidth: '40px',
	},
	selectItem: {
		color: '#000',
	},
});

function onChange(event, index, path, updateData) {
	const newValue = event.target.value;
	updateData(newValue, index, path);
}

function TableListItem({
	classes,
	from,
	to,
	index,
	updateData,
	removeRedirect,
}) {
	return (
		<TableRow>
			<TableCell classes={{ root: classes.tableCellNormal }}>
				<FormControl fullWidth>
					<Input
						error={from === ''}
						value={from}
						onChange={event => onChange(event, index, 'from', updateData)}
					/>
				</FormControl>
			</TableCell>
			<TableCell classes={{ root: classes.tableCellNormal }}>
				<FormControl fullWidth>
					<Input
						error={to === ''}
						value={to}
						onChange={event => onChange(event, index, 'to', updateData)}
					/>
				</FormControl>
			</TableCell>
			<TableCell classes={{ root: classes.tableCellNormal }}>
				<FormControl disabled fullWidth>
					<Select
						value={0}
						inputProps={{
							name: 'statusCode',
						}}
					>
						<MenuItem classes={{ root: classes.selectItem }} value={0}>
							None
						</MenuItem>
						<MenuItem classes={{ root: classes.selectItem }} value={301}>
							301 - Moved Permanently
						</MenuItem>
						<MenuItem classes={{ root: classes.selectItem }} value={302}>
							302 - Found
						</MenuItem>
						<MenuItem classes={{ root: classes.selectItem }} value={303}>
							303 - See Other
						</MenuItem>
					</Select>
				</FormControl>
			</TableCell>
			<TableCell classes={{ root: classes.tableCellIcon }}>
				<Button
					classes={{ root: classes.button }}
					color="primary"
					aria-label="add redirect"
					onClick={() => removeRedirect(index)}
				>
					<Icon classes={{ root: classes.addButtonIcon }}>
						indeterminate_check_box
					</Icon>
				</Button>
			</TableCell>
		</TableRow>
	);
}

TableListItem.propTypes = {
	classes: PropTypes.object.isRequired,
	from: PropTypes.string,
	to: PropTypes.string,
	index: PropTypes.number,
	updateData: PropTypes.func,
	removeRedirect: PropTypes.func,
};

const TableComponentWithStyles = withStyles(styles)(TableListItem);

export { TableComponentWithStyles as TableListItem };
