import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Grid,
	Typography,
} from '@material-ui/core';
import {
	addRedirect,
	discardAppData,
	removeRedirect,
	updateRedirectsData,
} from '../../../actions/configActions';
import { PATH_REDIRECTS } from '../../../constants';
import { getAppData } from '../../../selectors/getAppData';
import { DiscardButton } from '../DiscardButton/DiscardButton';
import { RedirectsList } from '../RedirectsList/RedirectsList';
import { TableHeaderComponent } from '../TableHeader/TableHeaderComponent';
import * as css from './PageRedirects.css';

function PageRedirects({
	redirectsData,
	updateRedirects,
	discard,
	add,
	remove,
}) {
	return (
		<Grid container>
			<Grid item sm={10}>
				<div className={css.contentHeader}>
					<Typography variant="h3">Redirects</Typography>
				</div>
			</Grid>
			<Grid container item sm={2} alignItems="center">
				<Grid item>
					<DiscardButton text="Discard Changes" onClick={discard} />
				</Grid>
			</Grid>
			<TableHeaderComponent add={add} />
			<RedirectsList
				data={redirectsData}
				updateData={updateRedirects}
				remove={remove}
			/>
		</Grid>
	);
}

PageRedirects.propTypes = {
	redirectsData: PropTypes.any,
	updateRedirects: PropTypes.func,
	add: PropTypes.func,
	remove: PropTypes.func,
	discard: PropTypes.func,
};

const mapStateToProps = state => {
	const appData = getAppData(state, PATH_REDIRECTS);
	if (appData) {
		return {
			redirectsData: appData,
		};
	}
	return {};
};

const mapDispatchToProps = dispatch => ({
	updateRedirects: (newValue, index, prop) =>
		dispatch(updateRedirectsData(newValue, index, prop, PATH_REDIRECTS)),
	discard: () => dispatch(discardAppData(PATH_REDIRECTS)),
	add: (fromValue, toValue) =>
		dispatch(addRedirect(fromValue, toValue, PATH_REDIRECTS)),
	remove: index => dispatch(removeRedirect(index, PATH_REDIRECTS)),
});

const ConnectedPageRedirects = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PageRedirects);

export { ConnectedPageRedirects as PageRedirects };
