import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { connect } from 'react-redux';
import {
	Grid,
	Typography,
} from '@material-ui/core';
import {
	discardAppData,
	updateConfigDraftData,
} from '../../../actions/configActions';
import { PATH_TRACKINGCODES } from '../../../constants';
import { getAppData } from '../../../selectors/getAppData';
import { DiscardButton } from '../DiscardButton/DiscardButton';
import { InputField } from '../InputField/InputField';
import * as css from './PageTrackingCodes.css';

function PageTrackingCodes({
	trackingCodesData,
	udpateConfigDraft,
	discardConfigDraft,
}) {
	return (
		<Grid container>
			<Grid item sm={10}>
				<div className={css.contentHeader}>
					<Typography variant="h3">Tracking Codes</Typography>
				</div>
			</Grid>
			<Grid container item sm={2} alignItems="center">
				<Grid item>
					<DiscardButton
						onClick={discardConfigDraft}
						text="Discard Changes"
					/>
				</Grid>
			</Grid>
			<InputField
				title="Google - GTM Code"
				label="Code"
				path="google.gtmCode"
				value={pathOr('', ['google', 'gtmCode'], trackingCodesData)}
				onChange={udpateConfigDraft}
				type="text"
			/>
			<InputField
				title="Yandex - Metrica Site ID"
				label="ID"
				path="yandex.metricaSiteId"
				value={pathOr('', ['yandex', 'metricaSiteId'], trackingCodesData)}
				onChange={udpateConfigDraft}
				type="text"
			/>

			<InputField
				title="Hotjar - Site ID"
				label="ID"
				path="hotjar.siteId"
				value={pathOr('', ['hotjar', 'siteId'], trackingCodesData)}
				onChange={udpateConfigDraft}
				type="text"
			/>
		</Grid>
	);
}

PageTrackingCodes.propTypes = {
	trackingCodesData: PropTypes.object,
	udpateConfigDraft: PropTypes.func.isRequired,
	discardConfigDraft: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	const appData = getAppData(state, PATH_TRACKINGCODES);

	return {
		trackingCodesData: appData || undefined,
	};
};

const mapDispatchToProps = dispatch => ({
	udpateConfigDraft: (newValue, path) =>
		dispatch(updateConfigDraftData(newValue, path, PATH_TRACKINGCODES)),
	discardConfigDraft: () => dispatch(discardAppData(PATH_TRACKINGCODES)),
});

const ConnectedPageTrackingCodes = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PageTrackingCodes);
export { ConnectedPageTrackingCodes as PageTrackingCodes };
