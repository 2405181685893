import React from 'react';
import PropTypes from 'prop-types';
import {
	FormControl,
	Grid,
	Input,
	InputLabel,
	Typography,
} from '@material-ui/core';
import * as css from './InputField.css';

function handleChange(event, onChange) {
	const { name, value } = event.target;

	onChange(value, name.split('.'));
}

function InputField({ path, value, onChange, title, label, type }) {
	return (
		<div className={css.inputFieldContainer}>
			<Grid container alignItems="center">
				<Grid item sm={3}>
					<div className={css.inputFieldLabel}>
						<Typography variant="subtitle1">{title}</Typography>
					</div>
				</Grid>
				<Grid item sm={8}>
					<FormControl fullWidth>
						<InputLabel>{label}</InputLabel>
						<Input
							type={type}
							value={value}
							name={path}
							onChange={event => handleChange(event, onChange)}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</div>
	);
}

InputField.propTypes = {
	path: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};

export { InputField };
