import { path } from 'ramda';
import { getSelectedKey } from './getSelectedKey';
import { getSelectedAppKey } from './getSelectedAppKey';

export function getSelectedConfigPage(state, appName) {
	const app = appName || getSelectedAppKey(state);

	return path(
		[
			'ui',
			'byKey',
			getSelectedKey(state),
			'selectedApp',
			app,
			'selectedConfigPage',
		],
		state,
	);
}
