import React from 'react';
import { connect } from 'react-redux';
import { CssBaseline, Grid, MuiThemeProvider } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { userLoggedIn, userLoggedOut } from './actions/uiActions';
import { LoginRequired } from './Auth/LoginRequired';
import { Content } from './components/Content';
import { Header } from './components/Header';
import { NavigationBar } from './components/NavigationBar';
import { Sidebar } from './components/Sidebar';
import { ENVIRONMENTS, LOCAL } from './constants';
import themeCH1Prod from './themes/themeCH1Prod';
import themeCH1Test from './themes/themeCH1Test';
import themeDSTProd from './themes/themeDSTProd';
import themeDSTTest from './themes/themeDSTTest';
import themeLocal from './themes/themeLocal';
import themePretest from './themes/themePretest';
import themePreprod from './themes/themePreprod';
import themeProd from './themes/themeProd';
import themeTest from './themes/themeTest';
import themeUndefined from './themes/themeUndefinded';
import * as css from './App.css';

const [PRETEST, TEST, PREPROD, PROD, CH1TEST, CH1PROD, DSTTEST, DSTPROD] =
	ENVIRONMENTS;

function getThemeByEnv(env) {
	switch (env) {
		case LOCAL:
			return themeLocal;
		case PRETEST:
			return themePretest;
		case TEST:
			return themeTest;
		case PROD:
			return themeProd;
		case CH1TEST:
			return themeCH1Test;
		case PREPROD:
			return themePreprod;
		case CH1PROD:
			return themeCH1Prod;
		case DSTTEST:
			return themeDSTTest;
		case DSTPROD:
			return themeDSTProd;
		default:
			return themeUndefined;
	}
}

function App({ selectedEnv }) {
	return (
		<MuiThemeProvider theme={getThemeByEnv(selectedEnv)}>
			<CssBaseline />
			<LoginRequired>
				<Header />
				<NavigationBar />
				<Grid container>
					<Grid item sm={3} className={css.sideBar}>
						<Sidebar />
					</Grid>
					<Grid item sm={9} className={classNames(css.content)}>
						<Content />
					</Grid>
				</Grid>
			</LoginRequired>
		</MuiThemeProvider>
	);
}

App.propTypes = {
	selectedEnv: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const mapStateToProps = (state) => ({
	selectedEnv: state.ui.selectedEnvironment,
	isLoggedIn: state.ui.userIsLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
	logIn: () => dispatch(userLoggedIn()),
	logOut: () => dispatch(userLoggedOut()),
});

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export { connectedApp as App };
