import { createTheme } from '@material-ui/core';

const themeDSTTest = createTheme({
	palette: {
		primary: {
			main: '#58D68D',
		},
		secondary: {
			main: '#82E0AA',
		},
		background: {
			default: '#58D68D',
		},
	},
});

export default themeDSTTest;
