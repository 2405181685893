import { createTheme } from '@material-ui/core';

const themePretest = createTheme({
	palette: {
		primary: {
			main: '#A569BD',
		},
		secondary: {
			main: '#BB8FCE',
		},
		background: {
			default: '#A569BD',
		},
	},
});

export default themePretest;
