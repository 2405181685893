import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userLoggedIn } from '../actions/uiActions';
import { authorize, getUserInformation, isAuthenticated } from './Auth0Service';

class LoginRequired extends React.Component {
	async componentDidMount() {
		if (isAuthenticated()) {
			const { login } = this.props;
			const profile = await getUserInformation();
			login(profile);
		} else {
			authorize();
		}
	}

	render() {
		const {
			isLoggedIn,
			children
		} = this.props;

		if (isLoggedIn) {
			return children;
		}
		return null;
	}
}

LoginRequired.propTypes = {
	login: PropTypes.func,
	isLoggedIn: PropTypes.any,
	children: PropTypes.any,
};

const mapStateToProps = state => ({
	isLoggedIn: state.ui.userInformation.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
	login: profile => dispatch(userLoggedIn(profile)),
});

const connectedLoginRequired = connect(
	mapStateToProps,
	mapDispatchToProps,
)(LoginRequired);

export { connectedLoginRequired as LoginRequired };
