import { pathOr } from 'ramda';
import { getSelectedKey } from './getSelectedKey';
import { getSelectedAppKey } from './getSelectedAppKey';

export function getHasEditorErrors(state) {
	const configKey = getSelectedKey(state);
	const selectedApp = getSelectedAppKey(state);
	return pathOr(
		false,
		['editorState', configKey, selectedApp, 'hasErrors'],
		state,
	);
}
