import { pathOr } from 'ramda';
import { getSelectedKey } from './getSelectedKey';

export function getAppHasValidationErrors(state, appName) {
	const configKey = getSelectedKey(state);

	const ObjectToCheck = pathOr(
		null,
		['draftValidationState', configKey, appName],
		state,
	);
	if (!ObjectToCheck) {
		return false;
	}
	const values = Object.values(ObjectToCheck);

	return values.some(element => element === true);
}
