import { pathOr } from 'ramda';
import { getSelectedKey } from './getSelectedKey';

export function getHasValidationErrors(state, PATHS, appName) {
	const configKey = getSelectedKey(state);

	return pathOr(
		false,
		['draftValidationState', configKey, appName, PATHS],
		state,
	);
}
