import React from 'react';
import PropTypes from 'prop-types';
import {
	TableCell,
	TableRow,
	Typography,
	withStyles,
} from '@material-ui/core';

const styles = theme => ({
	tableCheck: {
		width: '15%',
	},
	tableText: {
		width: '85%',
	},
	addButtonIcon: {
		fontSize: '40px',
	},
	button: {
		padding: '8px',
		minWidth: '40px',
	},
	selectItem: {
		color: '#000',
	},
	root: {
		'&$selected': {
			backgroundColor: 'rgba(0, 0, 0, 0)',
		},
	},
	selected: {},
	checkBox: {
		'&$checked': {
			color: 'white',
			backgroundColor: theme.palette.primary.main,
		},
	},
	checked: {},
	domainText: { fontSize: '17px' },
});

function TableStatusItem({ classes, status }) {
	return (
		<TableRow
			hover
			classes={{ root: classes.root, selected: classes.selected }}
		>
			<TableCell
				scope="row"
				component="th"
				classes={{ root: classes.tableText }}
			>
				<Typography classes={{ root: classes.domainText }}>{status}</Typography>
			</TableCell>
			<TableCell classes={{ root: classes.tableCheck }} />
		</TableRow>
	);
}

TableStatusItem.propTypes = {
	classes: PropTypes.object.isRequired,
	status: PropTypes.string,
};

const TableComponentWithStyles = withStyles(styles)(TableStatusItem);

export { TableComponentWithStyles as TableStatusItem };
