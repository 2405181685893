import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { Paper, Table, TableBody, withStyles } from '@material-ui/core';
import { TableSelectItem } from '../Table/TableSelectItem';
import { TableStatusItem } from '../Table/TableStatusItem';

const styles = theme => ({
	activeList: {
		marginTop: theme.spacing(3),
		backgroundColor: theme.palette.secondary.light,
	},
	selectionList: {
		marginTop: theme.spacing(3),
		marginBottom: '50px',
	},
});

function getDomains(availableDomains, excludedDomains, listType, add, remove) {
	if (listType === 'selectionList') {
		if (availableDomains) {
			if (excludedDomains) {
				const filteredArray = availableDomains.filter(
					domain => excludedDomains.indexOf(domain) === -1,
				);
				if (filteredArray.length === 0) {
					return (
						<TableStatusItem status="All available domains are currently excluded..." />
					);
				}
				return filteredArray.map(domain => (
					<TableSelectItem
						key={domain}
						domain={domain}
						listType={listType}
						add={add}
						remove={remove}
					/>
				));
			}
			return availableDomains.map(domain => (
				<TableSelectItem
					key={domain}
					domain={domain}
					listType={listType}
					add={add}
					remove={remove}
				/>
			));
		}
		return <TableStatusItem status="There are no available domains." />;
	}
	if (listType === 'activeList') {
		if (excludedDomains && !isEmpty(excludedDomains)) {
			return excludedDomains.map(domain => (
				<TableSelectItem
					key={domain}
					domain={domain}
					listType={listType}
					add={add}
					remove={remove}
				/>
			));
		}
		return <TableStatusItem status="There are no active excluded domains!" />;
	}
	return null;
}

function SelectionList({
	classes,
	availableDomains,
	excludedDomains,
	listType,
	add,
	remove,
}) {
	return (
		<div>
			<Paper classes={{ root: classes[listType] }}>
				<Table>
					<TableBody>
						{getDomains(
							availableDomains,
							excludedDomains,
							listType,
							add,
							remove,
						)}
					</TableBody>
				</Table>
			</Paper>
		</div>
	);
}

SelectionList.propTypes = {
	classes: PropTypes.object,
	availableDomains: PropTypes.array,
	excludedDomains: PropTypes.array,
	listType: PropTypes.string,
	add: PropTypes.func,
	remove: PropTypes.func,
};

const SelectionListWithStyles = withStyles(styles)(SelectionList);

const ConnectedPageSelectionList = connect(
	null,
	null,
)(SelectionListWithStyles);

export { ConnectedPageSelectionList as SelectionList };
