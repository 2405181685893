import React from 'react';
import PropTypes from 'prop-types';
import {
	Grid,
	Switch,
	Typography,
} from '@material-ui/core';
import * as css from './SwitchComponent.css';

function onSwitch(event, onChange) {
	const { name, checked } = event.target;
	onChange(checked, name.split('.'));
}

function SwitchComponent({ path, value, onChange, title }) {
	return (
		<div className={css.switchComponentContainer}>
			<Grid container alignItems="center">
				<Grid item sm={3}>
					<div className={css.switchComponentFieldLabel}>
						<Typography variant="subtitle1">{title}</Typography>
					</div>
				</Grid>
				<Grid item sm={8}>
					<Switch
						name={path}
						color="primary"
						checked={value}
						onChange={event => onSwitch(event, onChange)}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

SwitchComponent.propTypes = {
	path: PropTypes.any,
	value: PropTypes.any,
	onChange: PropTypes.func,
	title: PropTypes.string,
};

export { SwitchComponent };
